import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  container: {
    fontFamily: 'GothamRounded'
  },

  titleFrame: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'GTWalsheim'
  },

  title: {
    fontSize: 38,
    fontWeight: 500,
    [theme.media.tablet]: {
      fontSize: 18
    },
    [theme.media.mobile]: {
      fontSize: 16
    },
    [theme.media.smallMobile]: {
      fontSize: 16
    }
  },

  tickIcon: {
    width: 38,
    [theme.media.tablet]: {
      width: 20
    },
    [theme.media.mobile]: {
      width: 18
    },
    [theme.media.smallMobile]: {
      width: 18
    }
  },

  discountPrice: {
    height: 44,
    [theme.media.tablet]: {
      height: 32
    },
    [theme.media.mobile]: {
      height: 28
    },
    [theme.media.smallMobile]: {
      height: 18
    }
  },

  currentPlanLabel: {
    fontSize: 16,
    fontFamily: 'GTWalsheim',
    [theme.media.mobile]: {
      fontSize: 12
    },
    [theme.media.smallMobile]: {
      fontSize: 10
    }
  },

  originalPrice: {
    color: '#767676',
    fontSize: 16,
    fontWeight: 450,
    textDecoration: 'line-through 1px', // for Chrome and Edge
    '-webkit-text-decoration-line': 'line-through', // for Safari
    whiteSpace: 'break-spaces',
    paddingTop: 18,
    [theme.media.tablet]: {
      fontSize: 12
    },
    [theme.media.mobile]: {
      fontSize: 12,
      paddingTop: 16
    },
    [theme.media.smallMobile]: {
      fontSize: 10,
      paddingTop: 14
    }
  },

  activeOriginalPrice: {
    color: theme.neonGreen
  },

  currency: {
    fontSize: 24,
    lineHeight: '24px',
    fontWeight: 400,
    [theme.media.tablet]: {
      fontSize: 18,
      lineHeight: '30px'
    },
    [theme.media.mobile]: {
      fontSize: 18,
      lineHeight: '30px'
    },
    [theme.media.smallMobile]: {
      fontSize: 16,
      lineHeight: '36px'
    }
  },

  priceAmount: {
    display: 'flex'
  },

  priceAmountRightFrame: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.media.tablet]: {
      justifyContent: 'flex-start'
    },
    [theme.media.mobile]: {
      justifyContent: 'flex-start'
    },
    [theme.media.smallMobile]: {
      justifyContent: 'center'
    }
  },

  priceAmountUnit: {
    fontSize: 16,
    fontWeight: 325,
    lineHeight: '15px',
    [theme.media.tablet]: {
      fontSize: 12
    },
    [theme.media.mobile]: {
      fontSize: 12
    },
    [theme.media.smallMobile]: {
      fontSize: 10
    }
  },

  priceAmountBeforeDot: {
    fontSize: 70,
    fontWeight: 600,
    lineHeight: '46px',
    paddingRight: 2,
    [theme.media.tablet]: {
      fontSize: 46
    },
    [theme.media.mobile]: {
      fontSize: 42
    },
    [theme.media.smallMobile]: {
      fontSize: 32
    }
  },

  priceAmountAfterDot: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '24px',
    [theme.media.tablet]: {
      fontSize: 18,
      lineHeight: '28px'
    },
    [theme.media.mobile]: {
      fontSize: 16,
      lineHeight: '24px'
    },
    [theme.media.smallMobile]: {
      fontSize: 12,
      lineHeight: '14px'
    }
  },

  badgeFrame: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: 15,
    minHeight: 50,
    [theme.media.tablet]: {
      justifyContent: 'center',
      minHeight: 0
    },
    [theme.media.mobile]: {
      justifyContent: 'center',
      minHeight: 0,
      marginTop: 8
    },
    [theme.media.smallMobile]: {
      justifyContent: 'center',
      minHeight: 0,
      marginTop: 4
    }
  },

  badge: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '24px',
    color: '#767676',
    [theme.media.tablet]: {
      fontSize: 12,
      textAlign: 'center',
      lineHeight: '14px'
    },
    [theme.media.mobile]: {
      textAlign: 'center',
      fontSize: 12,
      lineHeight: '14px'
    },
    [theme.media.smallMobile]: {
      textAlign: 'center',
      fontSize: 10,
      lineHeight: '12px'
    }
  },

  activeBadge: {
    color: theme.neonGreen
  },

  discountLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: [6, 10, 6, 10],
    minHeight: 34,
    backgroundColor: '#767676',
    borderRadius: [8, 0, 8, 0],
    color: '#000',
    fontWeight: 500,
    fontSize: 20,
    [theme.media.tablet]: {
      fontSize: 12,
      minHeight: 24,
      padding: [4, 8, 4, 8],
      width: '100%'
    },
    [theme.media.mobile]: {
      fontSize: 12,
      minHeight: 24,
      padding: [4, 8, 4, 8],
      width: '100%'
    },
    [theme.media.smallMobile]: {
      fontSize: 10,
      minHeight: 24,
      padding: [3, 6, 3, 6],
      width: '100%'
    }
  },

  activeDiscountLabel: {
    backgroundColor: theme.neonGreen
  }
}))
