import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../shared/common.styles'

const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignSelf: 'center',
    fontFamily: 'GTWalsheim',
    [theme.media.smallMobile]: {
      width: '90vw'
    },
    [theme.media.mobile]: {
      width: '90vw'
    },
    [theme.media.tablet]: {
      width: '93vw'
    },
    [theme.media.desktop]: {
      display: 'none'
    }
  },

  extraPadding: {
    [theme.media.mobile]: {
      width: '95vw',
      padding: [50, 0, 0, 0]
    },
    [theme.media.smallMobile]: {
      width: '100vw',
      padding: [50, 20, 0, 20]
    }
  },

  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignSelf: 'center',
    justifyContent: 'space-between',
    gap: '24px',
    width: '100%',
    [theme.media.tablet]: {
      gap: '10px'
    },
    [theme.media.mobile]: {
      gap: '10px'
    },
    [theme.media.smallMobile]: {
      gap: '8px'
    }
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 24
  },

  description: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 24
  },

  header: {
    fontSize: 16,
    fontWeight: 500
  },

  feature: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 40,
    width: '100%',
    borderRadius: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: [4, 12, 4, 12],
    marginTop: 12,
    fontSize: 16,
    fontWeight: 500,
    [theme.media.mobile]: {
      padding: [6, 10, 6, 10]
    },
    [theme.media.smallMobile]: {
      padding: [6, 8, 6, 8]
    }
  },

  featureTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '70%'
  },

  icon: {
    paddingRight: 12
  },

  notes: {
    fontSize: 14,
    color: 'rgba(255, 255, 255, 0.5)',
    marginTop: 24
  },

  buttonFrame: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    paddingTop: 10
  },

  button: {
    flexDirection: 'row',
    textAlign: 'center',
    color: '#333',
    width: '40%',
    padding: [10, 0],
    fontSize: 14,
    backgroundColor: theme.neonGreen,
    borderRadius: 4,
    fontWeight: 500,
    [theme.media.smallMobile]: {
      width: '90vw'
    },
    [theme.media.mobile]: {
      width: '90vw'
    }
  }
}))

export default useStyles
