import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../shared/common.styles'

const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  container: {
    fontFamily: 'GTWalsheim',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '28px',
    textAlign: 'center',
    fontStyle: 'normal',
    [theme.media.smallMobile]: {
      lineHeight: '24px',
      fontSize: 16
    }
  },

  availableVoucher: {
    margin: [-50, 0, 50, 0],
    color: theme.neonGreen
  },

  notAvailableVoucher: {
    margin: [-50, 0, 50, 0],
    color: theme.error
  },

  changeNotAllowedWarning: {
    margin: [-20, 0, 20, 0],
    color: theme.error,
    [theme.media.smallMobile]: {
      margin: 0
    }
  }
}))

export default useStyles
