import React from 'react'

import classnames from 'classnames'
import { getContext } from 'recompose'
import PropTypes from 'prop-types'
import styles from './swipe.css'

function Swipe(props) {
  const { children, slidesToShow, margin } = props
  const containerWidth = `calc(${100 / slidesToShow}% - ${margin /
    slidesToShow}px)`

  function wrapChildren(innerChildren, key) {
    const isLast = key === children.length - 1
    const updated = React.Children.map(innerChildren, child => {
      return React.cloneElement(child, {
        className: classnames(styles.itemStyle, child.props.className)
      })
    })
    return (
      <div
        key={key}
        className={styles.itemWrapper}
        style={{ width: containerWidth, marginRight: isLast ? margin : 0 }}
      >
        {updated}
      </div>
    )
  }

  return (
    <div className={styles.wrapper} style={{ paddingLeft: margin }}>
      {children.map(wrapChildren)}
    </div>
  )
}

Swipe.propTypes = {
  slidesToShow: PropTypes.number.isRequired,
  margin: PropTypes.number.isRequired
}

const enhance = getContext({
  slidesToShow: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired
})

export default enhance(Swipe)
