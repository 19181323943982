import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './rental-badge.css'

function TileRentalBadge({
  hide, isKids, isGrid
}) {
  return (
    <div
      className={classnames(styles.rentalBadgeWrapper, {
        [styles.hide]: hide,
        [styles.isGrid]: isGrid
      })}
    >
      <div
        className={classnames(styles.rentalBadge, {
          [styles.isKids]: isKids
        })}
      >
        $
      </div>
    </div>
  )
}

TileRentalBadge.propTypes = {
  hide: PropTypes.bool,
  isKids: PropTypes.bool,
  isGrid: PropTypes.bool
}

TileRentalBadge.defaultProps = {
  hide: false,
  isKids: false,
  isGrid: false
}

export default TileRentalBadge
