import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import qs from 'query-string'
import classNames from 'classnames'

import MessageSection from '../message-section'
import { MODALS, MANAGE_PLAN_OPTIONS } from '../../../constants'
import useStyles from './confirm-button-section.styles'

/**
 * Component display buttons for plan selection such as: "GET BASIC PLAN", "SWITCH TO STANDARD PLAN" and "GO BACK"
 * and relative messages e.g. "Sorry, your voucher isn't valid for this plan"
 */

const ConfirmButtonSection = React.memo(props => {
  const {
    activeSubscription,
    selectToNext,
    currentSubscription,
    oneWayChangeDirection,
    hasRecurringVouchers,
    inSignupJourney,
    planDetails
  } = props

  const styles = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [userSelectedPlan, setUserSelectedPlan] = useState(false)
  const [voucherAvailable, setVoucherAvailable] = useState(false)

  useEffect(() => {
    if (userSelectedPlan && !inSignupJourney) {
      // add subscription after user select plan which is not associated with voucher
      // Only go to next when user add voucher from my account and url has been changed
      selectToNext(activeSubscription.toUpperCase())
    }
  }, [location])

  const isCurrentSubscriptionSelected = currentSubscription === activeSubscription

  // Display warning info if only downgrade allowed and user select annual plan
  const upgradeIsNotAvailable =
    (oneWayChangeDirection === MANAGE_PLAN_OPTIONS.DOWNGRADE) &&
    (activeSubscription === planDetails.annual.title) &&
    !isCurrentSubscriptionSelected

  // Close the modal
  const goBack = () => {
    history.replace({
      ...location,
      search: null
    })
  }

  const getChangeSubscriptionModal = () => {
    if (currentSubscription === planDetails.basic.title || activeSubscription === planDetails.annual.title) {
      return MODALS.qsParams.upgradeSubscription
    }

    if (currentSubscription === planDetails.annual.title || activeSubscription === planDetails.basic.title) {
      return MODALS.qsParams.downgradeSubscription
    }

    return null
  }

  const getChangeSubscriptionPlan = () => {
    return [planDetails.basic, planDetails.standard, planDetails.annual].find(item => item.title === activeSubscription).sku
  }

  const changeSubscription = () => {
    setUserSelectedPlan(true)

    if (currentSubscription) {
      // change subscription
      // the voucher warning modal shows before payment modal when the recurringVouchers exist
      hasRecurringVouchers === 'true'
        ? history.replace({
          ...location,
          search: qs.stringify({
            modal: MODALS.qsParams.subscriptionVoucherWarn,
            nextModal: getChangeSubscriptionModal(),
            plan: getChangeSubscriptionPlan()
          })
        })
        : history.replace({
          ...location,
          search: qs.stringify({
            modal: getChangeSubscriptionModal(),
            plan: getChangeSubscriptionPlan()
          })
        })
    } else if (!voucherAvailable && !inSignupJourney) {
      const preUrlParams = qs.parse(location.search)
      // clear voucher in url then add subscription from my account
      delete preUrlParams.voucher
      history.replace({
        ...location,
        search: qs.stringify({
          ...preUrlParams
        })
      })
    } else {
      // add subscription when sign up or user who do not have subscription
      selectToNext(activeSubscription.toUpperCase())
    }
  }

  const getConfirmButtonText = () => {
    // For new user, the text could be "GET BASIC PLAN"
    let button = `Get ${activeSubscription} Plan`
    /**
     * For existing user who select the current plan, the text could be "CONTINUE ANNUAL PLAN"
     * For existing user who select other plan, the text could be "SWITCH TO STANDARD PLAN"
     */
    if (currentSubscription) {
      button = `${isCurrentSubscriptionSelected ? 'Continue' : 'Switch to'}  ${activeSubscription} Plan`
    }
    return button.toUpperCase()
  }

  const getCloseButtonText = () => {
    // When existing user select the current plan, the text is "GO BACK"
    let button = `Go Back`
    // When existing user select the other plan, the text could be "KEEP ANNUAL PLAN"
    if (!isCurrentSubscriptionSelected) {
      button = `Keep ${currentSubscription} Plan`
    }
    return button.toUpperCase()
  }

  return (
    <div className={styles.container}>
      {/* Confirm button for paln selection */}
      <div
        className={classNames(styles.outlineButton, styles.selectPlanButton, (isCurrentSubscriptionSelected || upgradeIsNotAvailable) && styles.disabledButton)}
        onClick={() => changeSubscription()}
        data-e2e="select-subscription-confirm-button"
      >
        {getConfirmButtonText()}
      </div>

      {/* Existing user will have an extra button to close the modal */}
      {currentSubscription && (
        <div
          className={classNames(styles.outlineButton, styles.selectPlanButton, styles.closeButton)}
          onClick={goBack}
          data-e2e="select-subscription-go-back-button"
        >
          {getCloseButtonText()}
        </div>
      )}

      <MessageSection
        activeSubscription={activeSubscription}
        inSignupJourney={inSignupJourney}
        upgradeIsNotAvailable={upgradeIsNotAvailable}
        updateVoucherAvailable={(value) => setVoucherAvailable(value)}
        planDetails={planDetails}
      />
    </div>
  )
})

ConfirmButtonSection.propTypes = {
  activeSubscription: PropTypes.string.isRequired,
  selectToNext: PropTypes.func.isRequired,
  currentSubscription: PropTypes.string.isRequired,
  oneWayChangeDirection: PropTypes.string.isRequired,
  hasRecurringVouchers: PropTypes.string.isRequired,
  inSignupJourney: PropTypes.bool.isRequired,
  planDetails: PropTypes.shape({
    basic: PropTypes.shape({
      title: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired
    }),
    standard: PropTypes.shape({
      title: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired
    }),
    annual: PropTypes.shape({
      title: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired
    })
  }).isRequired
}

export default ConfirmButtonSection
