import devices from '../../../images/devices.svg'
import liveTv from '../../../images/live-tv.svg'
import download from '../../../images/download.svg'
import group from '../../../images/group.svg'
import player from '../../../images/player-icon.svg'
import { planType } from '../shared/subscription-constants'

export const ICONS = [
  {
    name: 'devices',
    icon: devices
  },
  {
    name: 'download',
    icon: download
  },
  {
    name: 'quality',
    icon: liveTv
  },
  {
    name: 'group',
    icon: group
  },
  {
    name: 'player',
    icon: player
  }
]

export const priceTableTitle = 'Choose the way you pay'

export const priceTableSubTitle = 'Unlimited access to TV series and movies'

export const featuresForWeb = {
  screens: {
    iconName: 'devices',
    value: '2 screens watching at the same time'
  },
  profiles: {
    iconName: 'group',
    value: '5 profiles for the household'
  },
  HDQuality: {
    iconName: 'quality',
    value: 'Full HD (1080p) video quality'
  },
  videoAds: {
    iconName: 'player',
    value: 'Video Ads*'
  },
  pauseAds: {
    iconName: 'player',
    value: 'Uninterrupted viewing with no Video Ads*'
  },
  download: {
    iconName: 'download',
    value: 'Download and watch offline'
  }
}

export const featuresForMobile = {
  screens: {
    iconName: 'devices',
    key: 'Screens watching at the same time',
    value: '2 screens'
  },
  profiles: {
    iconName: 'group',
    key: 'Profiles for the household',
    value: '5 profiles'
  },
  HDQuality: {
    iconName: 'quality',
    key: 'Video Quality',
    value: 'Full HD (1080p)'
  },
  videoAdsYes: {
    iconName: 'player',
    key: 'Video Ads*',
    value: 'Yes'
  },
  videoAdsNo: {
    iconName: 'player',
    key: 'Video Ads*',
    value: 'No'
  },
  downloadYes: {
    iconName: 'download',
    key: 'Downloads',
    value: 'Yes'
  },
  downloadNo: {
    iconName: 'download',
    key: 'Downloads',
    value: 'No'
  }
}

const basicPlanPrice = '12.99'

export const basicPlan = {
  id: 'b9f0c585-fbaa-4617-9366-8ddbd23e2ba4',
  sku: 'LBBASICTV',
  type: 'BASIC',
  badge: 'BEST VALUE',
  title: 'Basic',
  header: 'WITH ADS',
  price: basicPlanPrice,
  discountPrice: '9.99',
  period: 'monthly',
  unit: '/month',
  description: 'Binge the best at our lowest price',
  notes: `<a target="_blank" href="/ads-on-neon" style="color:rgba(255, 255, 255, 0.5);text-decoration:underline;text-underline-offset:2px">*Learn more</a> about Ads on Neon.`,
  discountNotes: `Offer ends 14/01/2025. After 3 Months you plan will auto-renew at $12.99/m. <a target="_blank" href="/basic-plan-offer" style="color:rgba(255, 255, 255, 0.5);text-decoration:underline;text-underline-offset:2px">T&C’s apply</a>.`,
  planFeaturesWeb: [featuresForWeb.screens, featuresForWeb.profiles, featuresForWeb.HDQuality, featuresForWeb.videoAds],
  planFeaturesMobile: [featuresForMobile.screens, featuresForMobile.profiles, featuresForMobile.HDQuality, featuresForMobile.downloadNo, featuresForMobile.videoAdsYes],
  welcomeBackHeader: 'Welcome back! Get NEON Basic plan for only $9.99 per month for 3 months',
  welcomeBackMessage: 'Click to add your plan now. Limited time offer, ends 8 December 2024.',
  staticDetail: {
    name: 'Basic with Ads Plan',
    value: `$${basicPlanPrice}/month`
  },
  offerDetail: {
    name: 'BASIC WITH ADS PLAN',
    desc: `$${basicPlanPrice} per month, cancel anytime`
  },
  voucherDetail: {
    header: 'This Basic with Ads Plan voucher has been added to your account',
    subHeader: 'What’s included in the Basic with Ads Plan',
    desc: ['Unlimited access to TV shows and movies', 'Watch on 2 screens at the same time', 'Create up to 5 User Profiles', 'HD video quality', `Includes video ads and pause ads (<a target="_blank" href="/ads-on-neon" style="color:#cafd34;text-decoration:underline;text-underline-offset:2px">learn more</a>)`],
    summary: `Basic with Ads Plan will cost $${basicPlanPrice} per month after your voucher expires`
  }
}

const standardPlanPrice = '19.99'

export const standardPlan = {
  id: '47164451-2432-4beb-a4c4-9978084cc78f',
  sku: 'LBPREMTV',
  type: 'STANDARD',
  badge: 'Most Popular',
  title: 'Standard',
  header: 'Uninterrupted Viewing',
  price: standardPlanPrice,
  discountPrice: '17.99',
  period: 'monthly',
  unit: '/month',
  description: 'Get the goods with less disruption',
  notes: `<a target="_blank" href="/ads-on-neon" style="color:rgba(255, 255, 255, 0.5);text-decoration:underline;text-underline-offset:2px">*Learn more</a> about Ads on Neon.`,
  discountNotes: `Offer ends 14/01/2025. After 3 Months you plan will auto-renew at $17.99/m. <a target="_blank" href="/standard-plan-offer" style="color:rgba(255, 255, 255, 0.5);text-decoration:underline;text-underline-offset:2px">T&C’s apply</a>.`,
  planFeaturesWeb: [featuresForWeb.screens, featuresForWeb.profiles, featuresForWeb.HDQuality, featuresForWeb.pauseAds, featuresForWeb.download],
  planFeaturesMobile: [featuresForMobile.screens, featuresForMobile.profiles, featuresForMobile.HDQuality, featuresForMobile.downloadYes, featuresForMobile.videoAdsNo],
  welcomeBackHeader: 'Welcome back! Get 50% off for 3 months, only $9.99 per month on a Standard plan',
  welcomeBackMessage: 'Click to add your plan now. Limited time offer, ends 16 November 2024.',
  staticDetail: {
    name: 'Standard Plan',
    value: `$${standardPlanPrice}/month`
  },
  offerDetail: {
    name: 'STANDARD PLAN',
    desc: `$${standardPlanPrice} per month, cancel anytime`
  },
  voucherDetail: {
    header: 'This Standard Plan voucher has been added to your account',
    subHeader: 'What’s included in the Standard Plan',
    desc: ['Unlimited access to TV shows and movies', 'Watch on 2 screens at the same time', 'Create up to 5 User Profiles', 'Download and Go on selected content', 'HD video quality', `Pause ads only (<a target="_blank" href="/ads-on-neon" style="color:#cafd34;text-decoration:underline;text-underline-offset:2px">learn more</a>)`],
    summary: `Standard Plan will cost $${standardPlanPrice} per month after your voucher expires`
  }
}

const standardPlanNewPrice = '23.99'

export const standardPlanWithNewPrice = {
  ...standardPlan,
  price: standardPlanNewPrice,
  staticDetail: {
    name: 'Standard Plan',
    value: `$${standardPlanNewPrice}/month`
  },
  offerDetail: {
    name: 'STANDARD PLAN',
    desc: `$${standardPlanNewPrice} per month, cancel anytime`
  },
  voucherDetail: {
    header: 'This Standard Plan voucher has been added to your account',
    subHeader: 'What’s included in the Standard Plan',
    desc: ['Unlimited access to TV shows and movies', 'Watch on 2 screens at the same time', 'Create up to 5 User Profiles', 'Download and Go on selected content', 'HD video quality', `Pause ads only (<a target="_blank" href="/ads-on-neon" style="color:#cafd34;text-decoration:underline;text-underline-offset:2px">learn more</a>)`],
    summary: `Standard Plan will cost $${standardPlanNewPrice} per month after your voucher expires`
  }
}

const annualPlanPrice = '199.99'

export const annualPlan = {
  id: 'd334eb95-ad36-446f-8807-e497b9a69be4',
  sku: 'NEONPRP12',
  type: 'ANNUAL',
  badge: 'Save 16% when you Prepay. $16.67/month',
  title: 'Annual',
  header: 'Uninterrupted Viewing',
  price: annualPlanPrice,
  discountPrice: '179.99',
  period: 'annually',
  unit: '/year',
  description: 'Save big when you prepay',
  notes: `<a target="_blank" href="/ads-on-neon" style="color:rgba(255, 255, 255, 0.5);text-decoration:underline;text-underline-offset:2px">*Learn more</a> about Ads on Neon.`,
  discountNotes: `Offer ends 14/01/2025. After 3 Months you plan will auto-renew at $179.99/m. <a target="_blank" href="/annual-plan-offer" style="color:rgba(255, 255, 255, 0.5);text-decoration:underline;text-underline-offset:2px">T&C’s apply</a>.`,
  planFeaturesWeb: [featuresForWeb.screens, featuresForWeb.profiles, featuresForWeb.HDQuality, featuresForWeb.pauseAds, featuresForWeb.download],
  planFeaturesMobile: [featuresForMobile.screens, featuresForMobile.profiles, featuresForMobile.HDQuality, featuresForMobile.downloadYes, featuresForMobile.videoAdsNo],
  welcomeBackHeader: 'Welcome back! Get 50% off for 3 months, only $99.99 per month on an Annual plan',
  welcomeBackMessage: 'Click to add your plan now. Limited time offer, ends 16 November 2024.',
  staticDetail: {
    name: 'Annual Plan',
    value: `$${annualPlanPrice}/year`
  },
  offerDetail: {
    name: 'ANNUAL PLAN',
    desc: `$${annualPlanPrice} for 12 months, prepay and save 16%`
  },
  voucherDetail: {
    header: 'This Annual Plan voucher has been added to your account',
    subHeader: 'What’s included in the Annual Plan',
    desc: ['Unlimited access to TV shows and movies', 'Watch on 2 screens at the same time', 'Create up to 5 User Profiles', 'Download and Go on selected content', 'HD video quality', `Pause ads only (<a target="_blank" href="/ads-on-neon" style="color:#cafd34;text-decoration:underline;text-underline-offset:2px">learn more</a>)`],
    summary: `Annual Plan will cost $${annualPlanPrice} per year after your voucher expires`
  },
  paymentCopy: 'Your Annual Plan will start immediately. Your Annual Plan will renew automatically after 12 months unless you cancel before your renewal date. If you decide to cancel you can do so anytime from My Account. If you cancel your Annual plan any time before your plan renews you will not be eligible for a refund of the amount paid but you can keep watching until your Annual plan ends.',
  legacyPaymentCopy: 'Your Annual plan will renew automatically after 12 months unless you cancel before your renewal date. If you decide to cancel you can do so anytime from My Account. If you cancel your Annual plan any time before your plan renews you will not be eligible for a refund of the amount paid but you can keep watching until your Annual plan ends.',
  upgradeCopy: 'This part payment is for the use of your new plan from today, until your plan renewal date. On your next renewal date, you\'ll be charged the full price for your upgraded plan.'
}

const annualPlanNewPrice = '239.99'

export const annualPlanWithNewPrice = {
  ...annualPlan,
  price: annualPlanNewPrice,
  badge: 'SAVE 16% WHEN YOU PREPAY. $20/MONTH',
  staticDetail: {
    name: 'Annual Plan',
    value: `$${annualPlanNewPrice}/year`
  },
  offerDetail: {
    name: 'ANNUAL PLAN',
    desc: `$${annualPlanNewPrice} for 12 months, prepay and save 16%`
  },
  voucherDetail: {
    header: 'This Annual Plan voucher has been added to your account',
    subHeader: 'What’s included in the Annual Plan',
    desc: ['Unlimited access to TV shows and movies', 'Watch on 2 screens at the same time', 'Create up to 5 User Profiles', 'Download and Go on selected content', 'HD video quality', `Pause ads only (<a target="_blank" href="/ads-on-neon" style="color:#cafd34;text-decoration:underline;text-underline-offset:2px">learn more</a>)`],
    summary: `Annual Plan will cost $${annualPlanNewPrice} per year after your voucher expires`
  }
}

export const legacyPlan = {
  id: 'e6721fe1-ae1f-42a5-9423-b6f9503faebd',
  sku: 'LBSTDTV',
  title: 'monthly',
  staticDetail: {
    name: 'Standard Plan',
    value: `$${standardPlanPrice}/month`
  }
}

export const legacyPlanWithNewPrice = {
  ...legacyPlan,
  staticDetail: {
    name: 'Standard Plan',
    value: `$${standardPlanNewPrice}/month`
  }
}

export const messages = {
  onlyDowngradeAllowed: 'You can only change to an Annual Plan after your next billing date.',
  voucherApplied: 'Your voucher applies to this plan',
  voucherNotApplied: 'Sorry, your voucher isn’t valid for this plan'
}

export const tvodDetails = {
  type: planType.TVOD,
  title: 'Just After A Movie?',
  subTitle: 'Pay for what you watch. No subscription needed.',
  content: 'Movie rental prices vary from $7.99 for New Release movies, $5.99 for Library movies and from $20.99 for Premium movies. ',
  rentMovieButtonText: 'Rent Movies Now',
  browseMovieButtonText: 'Browse Movie Rentals'
}
