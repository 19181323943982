import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../shared/common.styles'

const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignSelf: 'center',
    fontFamily: 'GTWalsheim',
    [theme.media.smallMobile]: {
      display: 'none'
    },
    [theme.media.mobile]: {
      display: 'none'
    },
    [theme.media.tablet]: {
      display: 'none'
    },
    [theme.media.desktop]: {
      width: '93vw'
    }
  },

  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    gap: '24px',
    width: '100%'
  },

  buttonFrame: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    paddingTop: 10
  },

  button: {
    flexDirection: 'row',
    textAlign: 'center',
    color: '#333',
    width: '40%',
    padding: [10, 0],
    fontSize: 14,
    backgroundColor: theme.neonGreen,
    borderRadius: 4,
    fontWeight: 500
  }
}))

export default useStyles
