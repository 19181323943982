import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { messages } from '../constants'
import { STORAGE_KEY_VOUCHER_APPLICABLE_PLANS } from '../../../storage-keys'
import useStyles from './message-section.styles'

/**
 * Component display voucher and warning message under the confirm button in price table
 */

const MessageSection = props => {
  const {
    activeSubscription, inSignupJourney, updateVoucherAvailable, upgradeIsNotAvailable, planDetails
  } = props
  const styles = useStyles()

  useEffect(() => {
    // run a function when the component unmounts
    return () => {
      // Clear the voucher info in session storage, otherwise voucher message will be in the normal select plan modal
      if (!inSignupJourney) {
        sessionStorage.removeItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS)
      }
    }
  }, [])

  const subscriptions = sessionStorage.getItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS)?.split(',')

  const voucherRelatedPlans = subscriptions ? [planDetails.basic, planDetails.standard, planDetails.annual].filter(
    item => subscriptions.includes(item.sku)
  ) : []

  // Display message e.g. "Your voucher applies to this plan"
  const VoucherMessage = () => {
    if ((inSignupJourney && voucherRelatedPlans.length > 0) || voucherRelatedPlans.length > 1) {
      if (voucherRelatedPlans.find(item => item.type.toLowerCase() === activeSubscription.toLowerCase())) {
        updateVoucherAvailable(true)
        return <p className={styles.availableVoucher}>{messages.voucherApplied}</p>
      }

      updateVoucherAvailable(false)
      return <p className={styles.notAvailableVoucher}>{messages.voucherNotApplied}</p>
    }

    return null
  }

  // Display message e.g. "You can only change to an Annual Plan after your next billing date."
  const WarningMessage = () => {
    if (upgradeIsNotAvailable) {
      return <div className={styles.changeNotAllowedWarning}>{messages.onlyDowngradeAllowed}</div>
    }

    return null
  }

  return (
    <div className={styles.container}>
      <VoucherMessage />
      <WarningMessage />
    </div>
  )
}

MessageSection.propTypes = {
  activeSubscription: PropTypes.string.isRequired,
  updateVoucherAvailable: PropTypes.func.isRequired,
  inSignupJourney: PropTypes.bool.isRequired,
  upgradeIsNotAvailable: PropTypes.bool.isRequired,
  planDetails: PropTypes.shape({
    basic: PropTypes.shape({
      sku: PropTypes.string.isRequired
    }),
    standard: PropTypes.shape({
      sku: PropTypes.string.isRequired
    }),
    annual: PropTypes.shape({
      sku: PropTypes.string.isRequired
    })
  }).isRequired
}

export default MessageSection
