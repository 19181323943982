import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { propType } from 'graphql-anywhere'
import { T } from 'ramda'
import classnames from 'classnames'
import OnVisible from 'react-on-visible'
import { useSelector } from 'react-redux'

import TileOverlay from '../../../containers/carousel/tile/overlay'
import TileBadge from '../../shared/badge'
import TileRentalBadge from '../../shared/rental-badge'
import CarouselImgix from '../../imgix/carousel-imgix'

import { getDetailsUrl } from '../../../lib/utils'
import RECOMMENDED_TILES_FRAGMENT from '../../../../graphql/fragments/recommended-tiles.gql'

import styles from './carousel-tile.css'

import { checkIsAuthenticated } from '../../../lib/auth'
import { dataLayerProductClick } from '../../../lib/analytics/datalayer'
import { TESTERS } from '../../../lib/tester-classnames'
import { useIsKid } from '../../../hooks/useIsKid'

import topTen1 from '../../../../images/neon/top-ten-1.svg'
import topTen2 from '../../../../images/neon/top-ten-2.svg'
import topTen3 from '../../../../images/neon/top-ten-3.svg'
import topTen4 from '../../../../images/neon/top-ten-4.svg'
import topTen5 from '../../../../images/neon/top-ten-5.svg'
import topTen6 from '../../../../images/neon/top-ten-6.svg'
import topTen7 from '../../../../images/neon/top-ten-7.svg'
import topTen8 from '../../../../images/neon/top-ten-8.svg'
import topTen9 from '../../../../images/neon/top-ten-9.svg'
import topTen10 from '../../../../images/neon/top-ten-10.svg'

const topTenImages = [
  topTen1, topTen2, topTen3, topTen4, topTen5, topTen6, topTen7, topTen8, topTen9, topTen10
]

export default function TopTenTile({
  tile,
  subType,
  listTitle,
  index,
  isOnMyList,
  addToMyList,
  removeFromMyList
}) {
  const mapState = useSelector(state => state)
  const { session } = mapState
  const isAuthenticated = session && checkIsAuthenticated(mapState)
  const [isMouseHover, setIsMouseHover] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const isKid = useIsKid()
  const badgeTitle = tile.badge
  const isRental = tile.contentItem.isRental

  if (!tile?.portraitImage) {
    return <div className={styles.preload} data-testid="preload-tile" />
  }

  const onMouseEnter = () => {
    setIsMouseHover(true)
  }

  const onMouseLeave = () => {
    setIsMouseHover(false)
  }

  const onVisibilityChange = () => {
    if (isVisible) {
      return
    }
    setIsVisible(true)
  }

  return (
    <div className={styles.topTenTileFrame}>
      <div className={styles.topTenImageFrame}>
        <img
          className={styles.topTenImage}
          src={topTenImages[index]}
          alt={`Top Ten ${index + 1}`}
        />
      </div>
      <div
        className={classnames(styles.tile, TESTERS.CAROUSEL_TILE, styles.topTenTile)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-lbx-e2e={getDetailsUrl(tile.contentItem)}
      >
        {badgeTitle ? (
          <TileBadge
            title={badgeTitle}
            isRental={isRental}
            isKids={isKid}
          />
        ) : null}

        <Link // eslint-disable-line jsx-a11y/no-static-element-interactions
          to={getDetailsUrl(tile.contentItem)}
          onClick={() => dataLayerProductClick(tile.contentItem, index, listTitle, window.location.pathname)}
          className={styles.imgWrapper}
        >
          {/* Set recipe to PORTRAIT, because images in TOP Ten are all portrait */}
          <CarouselImgix src={tile.portraitImage} recipe="PORTRAIT" isKids={isKid} />

          {isRental && (
            <TileRentalBadge hide={isKid && isMouseHover} />
          )}

          <TileOverlay
            tileType={subType}
            tile={tile}
            isAuthenticated={isAuthenticated}
            isOnMyList={isOnMyList}
            addToMyList={addToMyList}
            removeFromMyList={removeFromMyList}
            display={isMouseHover}
            isRental={isRental}
          />
        </Link>
      </div>
      {!isVisible && <OnVisible onChange={onVisibilityChange} />}
    </div>
  )
}

TopTenTile.propTypes = {
  tile: propType(RECOMMENDED_TILES_FRAGMENT).isRequired,
  subType: PropTypes.string,
  listTitle: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isOnMyList: PropTypes.bool,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func
}

TopTenTile.defaultProps = {
  subType: null,
  isOnMyList: false,
  addToMyList: T,
  removeFromMyList: T
}
