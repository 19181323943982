import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../shared/common.styles'

const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10
  },

  selectPlanButton: {
    zIndex: theme.zIndexCardBadge,
    position: 'relative',
    width: '45%',
    maxWidth: 756,
    color: theme.black,
    fontFamily: 'GothamRounded',
    backgroundColor: theme.neonGreen,
    margin: [30, 0, 62, 0],
    fontSize: 16,
    fontWeight: 450,
    lineHeight: '20px',
    [theme.media.tablet]: {
      width: '100%',
      maxWidth: 1023
    },
    [theme.media.mobile]: {
      width: '100%'
    },
    [theme.media.smallMobile]: {
      width: '100%'
    }
  },

  closeButton: {
    backgroundColor: 'rgba(91, 91, 91, 0.2)',
    color: theme.neonGreen,
    margin: [-40, 0, 40, 0],

    [theme.media.smallMobile]: {
      margin: [-40, 0, 20, 0]
    }
  },

  disabledButton: {
    pointerEvents: 'none',
    opacity: 0.4
  }
}))

export default useStyles
