import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { links } from '../../modules/shared/subscription-constants'
import { STORAGE_KEY_VOUCHER_APPLICABLE_PLANS, STORAGE_KEY_SELECTED_PLAN_ON_GUEST } from '../../storage-keys'
import PlanTableWeb from '../../modules/price-table/plan-table-web'
import PlanTableMobile from '../../modules/price-table/plan-table-mobile'
import { useSelectPlan } from '../../modules/signup/hooks/use-select-plan'
import styles from './subscription-table.css'

const SubscriptionTable = () => {
  const history = useHistory()
  // call useSelectPlan here so the activeSubscription can be shared by PlanTableWeb and PlanTableMobile
  const { activeSubscription, updateActiveSubscription } = useSelectPlan()

  useEffect(() => {
    // Remove following keys when users access guest home page.
    sessionStorage.removeItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS)
    sessionStorage.removeItem(STORAGE_KEY_SELECTED_PLAN_ON_GUEST)
  }, [])

  const nextStep = async (subscription) => {
    history.push({
      pathname: links.createAccount
    })
    /**
     * Save the selected plan on guest home page when users go to the create account page
     * So we can highlight this plan when users access the select plan table again
     */
    sessionStorage.setItem(STORAGE_KEY_SELECTED_PLAN_ON_GUEST, subscription)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentBackground}>
        <div className={styles.contentWrapper}>
          <PlanTableWeb
            selectToNext={nextStep}
            activeSubscription={activeSubscription}
            updateActiveSubscription={updateActiveSubscription}
            inSignupJourney
          />
          <PlanTableMobile
            selectToNext={nextStep}
            activeSubscription={activeSubscription}
            updateActiveSubscription={updateActiveSubscription}
            inSignupJourney
          />
        </div>
      </div>
    </div>
  )
}

export default SubscriptionTable
