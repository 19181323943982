import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { propType } from 'graphql-anywhere'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { pathOr } from 'ramda'

import CarouselImgix from '../../imgix/carousel-imgix'
import { getTileRecipe } from '../../imgix/recipes'
import { getDetailsUrl } from '../../../lib/utils'
import { isMobile } from '../../../utils/os-device'

import { THEME_OPTIONS } from '../../../constants'
import TILE_FRAGMENT from '../../../../graphql/fragments/tile.gql'

import styles from './carousel-tile.css'

import PlayProgressButton from '../../../containers/play-button/details-play-button'
import { getTimeLeftString } from '../../../containers/play-button/utils'
import {
  isEpisode,
  isTitle
} from '../../../lib/content'
import ContinuityOverlay from '../../../modules/continuity/component/overlay/continuity-overlay'
import ContinuityMobileOverlay from '../../../modules/continuity/component/overlay/continuity-mobile-overlay'
import { segmentTrackClickContentCard } from '../../../segment/segment-track'
import TileRentalBadge from '../../shared/rental-badge'

const ContinuityTile = React.memo(({
  tile,
  theme,
  playbackInfoMany
}) => {
  const [isMouseHoverRemoveIcon, setIsMouseHoverRemoveIcon] = useState(false)
  const mapState = useSelector(state => state)
  const { castSender } = mapState
  const history = useHistory()

  const tileRecipe = getTileRecipe('EPISODE')
  const rentalInfo = tile.rentalInfo || {
    canWatchUntilText: ''
  }

  const {
    contentItem,
    header,
    image,
    isInUndoState
  } = tile
  const title = isTitle(contentItem) ? header : contentItem.series.title
  const playbackInfo = playbackInfoMany && playbackInfoMany[contentItem.id]
  let time = ''
  if (playbackInfo && playbackInfo.items?.length > 0) {
    const playbackInfoItem = playbackInfo?.items?.find(
      item => item.contentItemId === playbackInfo.contentItemId
    )
    time = getTimeLeftString(playbackInfoItem.playbackInfo, true)
  }

  const episodeTileSubheader = isEpisode(contentItem) ? `S${contentItem.seasonNumber} E${tile.contentItem.episodeNumber} ` : ''
  const isRental = contentItem && contentItem.isRental
  const isKids = theme === THEME_OPTIONS.light

  const onClickToWatch = () => {
    const watchId = contentItem.id
    history.push(`/watch/${watchId}`)
  }

  const [isImageMouseHover, setImageIsMouseHover] = useState(false)

  const onHeaderClick = (event) => {
    event.stopPropagation()
    const url = getDetailsUrl(tile.contentItem)
    if (!castSender.isConnected) {
      history.push(url)
    }
  }

  let badgeTitle = tile.badge
  if (isEpisode(contentItem)) {
    badgeTitle = pathOr('', ['contentItem', 'series', 'tile', 'badge'], tile)
  }

  const updateMouseHoverRemoveIconState = (value) => {
    /**
     * displayOverlay is a separate div above play-button div (z-index)
     * pass the mouse hover state from display to play-button
     * so the play button can display when mouse hover on the remove icon in displayOverlay
     * */
    setIsMouseHoverRemoveIcon(value)
  }

  const displayOverlay = () => {
    if (isMobile) {
      return (
        <ContinuityMobileOverlay
          contentItem={contentItem}
          title={title}
          onClickToDetail={onHeaderClick}
          onClickToResumeWatching={onClickToWatch}
          isInUndoState={isInUndoState}
        />
      )
    }
    return (
      <ContinuityOverlay
        contentItem={contentItem}
        mouseHoverState={isImageMouseHover}
        isInUndoState={isInUndoState}
        updateMouseHoverRemoveIconState={updateMouseHoverRemoveIconState}
      />
    )
  }

  const getPlaybutton = () => {
    return (
      <div className={classNames(styles.ctaContainer, styles.smallPlay)}>
        {
          playbackInfoMany && (
            <PlayProgressButton
              size="small"
              contentItem={contentItem}
              playbackInfo={playbackInfo}
              fromMyRentals={contentItem.isRental}
              isMyRentalsBadge={!!badgeTitle}
              shouldUseRentalStyle={false}
              isMouseHoverRemoveIcon={isMouseHoverRemoveIcon}
              fromContinuityTile
            />
          )
        }
        <div className={classNames(styles.wrapper, {
          [styles.isRental]: isRental
        })}
        />
      </div>
    )
  }

  const displayPlaybutton = () => {
    if (!isInUndoState) {
      return getPlaybutton()
    }
    return null
  }

  const triggerSegment = () => {
    segmentTrackClickContentCard(contentItem, null, 'Continue Watching', '/')
  }

  return (
    <div
      className={classNames(styles.tile, theme, styles.myRentals)}
    >
      {isRental && (
        <TileRentalBadge />
      )}

      <div
        className={styles.imgWrapper}
        onMouseEnter={() => setImageIsMouseHover(true)}
        onMouseLeave={() => setImageIsMouseHover(false)}
        onClick={() => triggerSegment()}
      >
        <CarouselImgix
          src={image}
          recipe={tileRecipe}
          shouldCrop
          isKids={isKids}
        />
        {displayOverlay()}
        <div className={classNames(styles.ctaWrapper, {
          [styles.isRental]: isRental,
          [styles.isHover]: isImageMouseHover
        })}
        >
          {
            displayPlaybutton()
          }
        </div>
      </div>
      <div
        className={styles.tileInfoWrapper}
        onClick={onHeaderClick}
      >
        <div className={styles.header}>
          <div
            className={styles.title}
          >
            {title}
          </div>
        </div>
        {isRental && isTitle(contentItem) && (
          <div className={classNames(styles.timeLeftToWatch)}>
            {rentalInfo.canWatchUntilText}
          </div>
        )}
        {isEpisode(contentItem) && (
          <div className={styles.episodeSubheader}>
            {episodeTileSubheader}
            &middot;
            {time.toUpperCase()}
          </div>
        )}
        { !isRental && isTitle(contentItem) && (
          <div className={styles.episodeSubheader}>
            {time.toUpperCase()}
          </div>
        )}
      </div>
    </div>
  )
})

ContinuityTile.propTypes = {
  tile: propType(TILE_FRAGMENT).isRequired,
  theme: PropTypes.string.isRequired,
  playbackInfoMany: PropTypes.oneOfType([PropTypes.object])
}

ContinuityTile.defaultProps = {
  playbackInfoMany: null
}

export default ContinuityTile
