import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import PriceSection from '../price-section'
import { useStyles } from './plan-card-mobile.styles'

/**
 * Component display plan price and discount for mobile and tablet view
 */

const PlanCardMobile = React.memo(props => {
  const {
    plan, activeSubscription, updateActiveSubscription, displayDiscount, currentSubscription
  } = props
  const styles = useStyles()

  const isActive = activeSubscription === plan.title

  return (
    <div className={classNames(styles.container, isActive && styles.activeContainer)} onClick={() => updateActiveSubscription(plan.title)}>
      <div className={classNames(styles.header, isActive && styles.activeHeader)}>{plan.header.toUpperCase()}</div>

      <div className={styles.body}>
        <PriceSection
          plan={plan}
          activeSubscription={activeSubscription}
          currentSubscription={currentSubscription}
          displayDiscount={displayDiscount}
        />
      </div>
    </div>
  )
})

PlanCardMobile.propTypes = {
  plan: PropTypes.shape({
    header: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    priceBeforeDot: PropTypes.string,
    priceAfterDot: PropTypes.string,
    unit: PropTypes.string,
    discountPrice: PropTypes.string,
    badge: PropTypes.string
  }).isRequired,
  activeSubscription: PropTypes.string.isRequired,
  currentSubscription: PropTypes.string.isRequired,
  updateActiveSubscription: PropTypes.func.isRequired,
  displayDiscount: PropTypes.bool.isRequired
}

export default PlanCardMobile
