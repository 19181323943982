import {
  basicPlan, standardPlan, annualPlan, legacyPlan, standardPlanWithNewPrice, annualPlanWithNewPrice, legacyPlanWithNewPrice
} from '../modules/price-table/constants'
import { useFeatureFlags } from './useFeatureFlags'

const usePlanChangeFlag = () => {
/**
 * after the price change, we just need to remove FF
 * and keep the following object
 * {
 *   basic: basicPlan,
 *   standard: standardPlan,
 *   annual: annualPlan,
 *   legacy: legacyPlan
 * }
 *
 * and in next price change, we only need to use a new set of newPrices in constants file and new FF
 * For example:
 * const displayNewPrice = useFeatureFlags(['2025_price_change'])
 * if (displayNewPrice['2025_price_change']) { return new plans } else { return old plans }
 */

  const displayNewPrice = useFeatureFlags(['2025_price_change'])
  if (displayNewPrice['2025_price_change']) {
    // 2025_price_change will only change standard and annual plan price
    return {
      basic: basicPlan,
      standard: standardPlanWithNewPrice,
      annual: annualPlanWithNewPrice,
      legacy: legacyPlanWithNewPrice
    }
  }

  return {
    basic: basicPlan,
    standard: standardPlan,
    annual: annualPlan,
    legacy: legacyPlan
  }
}

export { usePlanChangeFlag }
