import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '3px solid #434343',
    backgroundColor: '#1a1a1a',
    borderRadius: 16,
    minHeight: 210,
    [theme.media.mobile]: {
      minHeight: 175
    },
    [theme.media.smallMobile]: {
      minHeight: 175
    }
  },

  activeContainer: {
    border: '3px solid #cafd34',
    background: `radial-gradient(77.48% 88.69% at 22.52% 41.37%, #2B3C07 0%, #000000 100%)`
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 38,
    borderRadius: [12, 12, 0, 0],
    backgroundColor: '#767676',
    width: '100%',
    color: '#000',
    fontSize: 12,
    fontWeight: 600,
    fontFamily: 'GothamRounded',
    [theme.media.mobile]: {
      fontSize: 10,
      height: 38
    },
    [theme.media.smallMobile]: {
      fontSize: 10,
      height: 34
    }
  },

  activeHeader: {
    backgroundColor: theme.neonGreen
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 16,
    minHeight: 140,
    [theme.media.tablet]: {
      padding: 10,
      minHeight: 120
    },
    [theme.media.mobile]: {
      padding: 8,
      minHeight: 120
    },
    [theme.media.smallMobile]: {
      padding: [8, 4, 0, 4],
      minHeight: 120
    }
  }
}))
