// TODO monthly plan can be removed when using standard plan

export const planType = {
  ANNUAL: 'ANNUAL',
  STANDARD: 'STANDARD',
  BASIC: 'BASIC',
  TVOD: 'TVOD',
  SIX_MONTH_OFFER: 'BUNDLE_2021_SIX_MONTH'
}

export const links = {
  planSelection: '/signup/plan',
  createAccount: '/signup/account',
  payment: '/signup/payment',
  confirmation: '/signup/confirmation',
  abandon: '/signup/abandon',
  error: '/signup/error',
  terms: '/pages/terms',
  privacy: '/pages/privacy',
  // TODO(AM): Update with actual help desk URL when available
  voucherHelp:
    'https://helpneon.freshdesk.com/support/solutions/articles/64000211109-how-do-i-sign-up-for-neon-',
  myAccountSubscription: '/my-account/subscription'
}

/**
 * State for used in router to identify previous sign up flow state
 */
export const signupSequence = {
  createAccount: 1,
  planSelection: 2,
  abandon: 2,
  payment: 3,
  confirmation: 4
}

/**
 * content for display in subscription modal
 */
export const modalContent = {
  selection: 'selection',
  voucher: 'voucher',
  payment: 'payment',
  paymentMethods: 'paymentMethod',
  confirmation: 'confirmation',
  voucherValidateErrorAfterLogin: 'voucherValidateErrorAfterLogin',
  error: 'error'
}

export const paymentCopy = {
  standard:
    'Please provide your credit card details below to complete your account set up. You can cancel any time.',
  basic:
    'Please provide your credit card details below to complete your account set up. You can cancel any time.',
  tvod: 'No upfront charges. No monthly subscription. Pay as you go',
  annual:
    'Please provide your credit card details below to complete your account set up.'
}

export const serverErrors = {
  VOUCHER_EXPIRED: 'Sorry, the voucher you have used has expired',
  VOUCHER_INVALID: 'Sorry, the voucher you have used is not valid',
  VOUCHER_REDEEMED: 'Sorry, this voucher has already been redeemed',
  DEFAULT:
    'We had an issue while trying to process your request, please try again or contact one of our support representatives if the problem persists'
}

export const creditCardStatus = {
  ACTIVE: 'ACTIVE'
}
export const signupImage =
  'https://lightbox-prod.imgix.net/images/static/neon-signup-devices.png?q=75'

export const priceChangeAlertMessage = {
  myAccount: `Neon's price is changing. A monthly Standard Plan will increase to $23.99 per month or Annual Plan will increase to $239.99 for 12 months if your next billing date is on or after 1 April 2025.`,
  standard: `<b>Neon's price is changing</b>. Your new Standard Plan will increase to $23.99 per month if your plan renews on or after 1 April.`,
  annual: `<b>Neon's price is changing</b>. Your new Annual Plan will increase to $239.99 per month if your plan renews on or after 1 April.`,
  modalHeader: `Neon's price is changing`,
  modalMessage: `On 1 April 2025 Neon's Standard Plan will increase to $23.99 per month, and Annual Plan will increase to $239.99 for 12 months.`
}

export const paymentAlertMessage = 'Please don’t refresh the page after submitting payment.'
