import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { propType } from 'graphql-anywhere'
import { T } from 'ramda'
import classnames from 'classnames'
import OnVisible from 'react-on-visible'
import { useSelector } from 'react-redux'

import TileOverlay from '../../../containers/carousel/tile/overlay'
import TileBadge from '../../shared/badge'
import CarouselImgix from '../../imgix/carousel-imgix'
import { getTileRecipe } from '../../imgix/recipes'
import { Rating } from '../../rating'

import { getDetailsUrl } from '../../../lib/utils'
import RECOMMENDED_TILES_FRAGMENT from '../../../../graphql/fragments/recommended-tiles.gql'

import styles from './carousel-tile.css'
import rentalBadgeSmall from '../../../../images/neon/rental-badge-small.svg'

import { checkIsAuthenticated } from '../../../lib/auth'
import { dataLayerProductClick } from '../../../lib/analytics/datalayer'
import { getDurationTextFromMinutes } from '../../../lib/date-time'
import { TESTERS } from '../../../lib/tester-classnames'
import { useIsKid } from '../../../hooks/useIsKid'
import { SCREEN_SIZE_BREAKPOINTS } from '../../../constants'

export default function SecondaryHeroTile({
  tile,
  subType,
  listTitle,
  index,
  isOnMyList,
  addToMyList,
  removeFromMyList,
  screenWidth
}) {
  const mapState = useSelector(state => state)
  const { session } = mapState
  const isAuthenticated = session && checkIsAuthenticated(mapState)
  const [isMouseHover, setIsMouseHover] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const isKid = useIsKid()
  const badgeTitle = tile.badge
  const isRental = tile.contentItem.isRental
  const rating = tile.contentItem.rating
  const showPortrait = screenWidth < SCREEN_SIZE_BREAKPOINTS.MIN_667
  const tileType = showPortrait ? `${subType}_PORTRAIT` : subType
  const tileRecipe = getTileRecipe(tileType)

  const genres = tile.contentItem.genres.map(genre => (
    <span key={genre}>{genre}</span>
  ))
  const duration = tile.contentItem.duration
    ? <span>{getDurationTextFromMinutes(tile.contentItem.duration)}</span>
    : null
  let season = null
  if (tile.contentItem.seasons?.length) {
    const sortedSeason = tile.contentItem?.seasons.sort((a, b) => a.seasonNumber - b.seasonNumber)
    const seasonCount = sortedSeason.length > 1
      ? `SEASONS ${sortedSeason[0].seasonNumber} - ${sortedSeason[sortedSeason.length - 1].seasonNumber}`
      : `SEASON ${sortedSeason[0].seasonNumber}`
    season = <span>{seasonCount}</span>
  }
  const onMouseEnter = () => {
    setIsMouseHover(true)
  }

  const onMouseLeave = () => {
    setIsMouseHover(false)
  }

  const onVisibilityChange = () => {
    if (isVisible) {
      return
    }
    setIsVisible(true)
  }

  return (
    <>
      <div
        className={classnames(styles.secondaryHeroTile, TESTERS.CAROUSEL_TILE)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-lbx-e2e={getDetailsUrl(tile.contentItem)}
      >
        {badgeTitle ? (
          <TileBadge
            title={badgeTitle}
            isRental={isRental}
            isKids={isKid}
          />
        ) : null}

        <Link // eslint-disable-line jsx-a11y/no-static-element-interactions
          to={getDetailsUrl(tile.contentItem)}
          onClick={() => dataLayerProductClick(tile.contentItem, index, listTitle, window.location.pathname)}
          className={styles.imgWrapper}
        >
          {tile.heroImage ? (
            <CarouselImgix src={tile.heroImage} recipe={tileRecipe} isKids={isKid} shouldCrop />
          ) : (
            <div className={classnames(styles.preload, styles.secondaryHeroPreload)} data-testid="preload-secondary-hero-tile" />
          )}

          {isRental && (
            <img
              className={classnames(styles.rentalBadge, {
                [styles.rentalBadgeInKidsRentals]: isKid && isMouseHover
              })}
              src={rentalBadgeSmall}
              alt="Rental Content"
            />
          )}

          <div className={styles.tileDetail}>
            <div className={styles.title}>
              {tile.contentItem.title}
            </div>
            <div className={styles.description}>
              {tile.contentItem.description}
            </div>
            <div className={styles.box}>
              {rating && (
                <Rating rating={rating} className={styles.ratingLabel} />
              )}
              {genres}
              {duration}
              {season}
            </div>
          </div>

          <TileOverlay
            tileType={subType}
            tile={tile}
            isAuthenticated={isAuthenticated}
            isOnMyList={isOnMyList}
            addToMyList={addToMyList}
            removeFromMyList={removeFromMyList}
            display={isMouseHover}
            isRental={isRental}
          />
        </Link>
      </div>
      {!isVisible && <OnVisible onChange={onVisibilityChange} />}
    </>
  )
}

SecondaryHeroTile.propTypes = {
  tile: propType(RECOMMENDED_TILES_FRAGMENT).isRequired,
  subType: PropTypes.string,
  listTitle: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isOnMyList: PropTypes.bool,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  screenWidth: PropTypes.number.isRequired
}

SecondaryHeroTile.defaultProps = {
  subType: null,
  isOnMyList: false,
  addToMyList: T,
  removeFromMyList: T
}
