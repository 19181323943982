import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignSelf: 'center',
    flexDirection: 'column',
    marginBottom: 60,
    width: '100%',
    border: '3px solid #434343',
    boxSizing: 'border-box',
    borderRadius: 16,
    padding: 24,
    maxWidth: 1120
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '16px',
    [theme.media.mobile]: {
      gap: '12px'
    },
    [theme.media.smallMobile]: {
      gap: '12px'
    }
  },

  rentalBadge: {
    marginTop: -24
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },

  title: {
    fontSize: 30,
    fontWeight: 600,
    color: theme.neonPink,
    [theme.media.mobile]: {
      fontSize: 24
    },
    [theme.media.smallMobile]: {
      fontSize: 22
    }
  },

  subTitle: {
    fontSize: 30,
    fontWeight: 600,
    [theme.media.mobile]: {
      fontSize: 24
    },
    [theme.media.smallMobile]: {
      fontSize: 22
    }
  },

  description: {
    fontSize: 16,
    color: '#8C8C8C',
    fontWeight: 500,
    maxWidth: 665,
    lineHeight: '24px',
    [theme.media.mobile]: {
      fontSize: 14,
      lineHeight: '18px'
    },
    [theme.media.smallMobile]: {
      fontSize: 14,
      lineHeight: '18px'
    }
  },

  buttonFrame: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
    width: '100%',
    [theme.media.mobile]: {
      flexDirection: 'column'
    },
    [theme.media.smallMobile]: {
      flexDirection: 'column'
    },
    textAlign: 'center'
  },

  button: {
    zIndex: theme.zIndexCardBadge,
    fontFamily: 'GothamRounded',
    position: 'relative',
    fontSize: 16,
    fontWeight: 450,
    lineHeight: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [16, 20, 16, 20],
    borderRadius: 4
  },

  rentMovieButton: {
    color: 'black',
    backgroundColor: theme.neonPink
  },

  browseMovieButton: {
    marginLeft: 20,
    color: theme.neonPink,
    backgroundColor: 'rgba(91, 91, 91, 0.2)',
    [theme.media.mobile]: {
      margin: [16, 0, 0, 0]
    },
    [theme.media.smallMobile]: {
      margin: [16, 0, 0, 0]
    }
  }
}))
