import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import PlanCardWeb from '../plan-card-web'
import ConfirmButtonSection from '../confirm-button-section'
import TitleSection from '../title-section'
import RentalSection from '../rental-section'
import { usePlanChangeFlag } from '../../../hooks/usePlanChange'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { useSubscriptionModalSelection } from '../../add-subscription-modal/hooks/use-subscription-modal-selection'
import useStyles from './plan-table-web.styles'

/**
 * Component display plan options for web view
 */

const PlanTableWeb = props => {
  const {
    currentSubscription, selectToNext, oneWayChangeDirection,
    hasRecurringVouchers, inSignupJourney, activeSubscription,
    updateActiveSubscription
  } = props

  const styles = useStyles()
  const location = useLocation()
  const planDetails = usePlanChangeFlag()
  const flags = useFeatureFlags(['discount_basic', 'discount_standard', 'discount_annual'])
  const { processSelection } = useSubscriptionModalSelection()

  return (
    <div className={styles.container}>
      <TitleSection />

      {/* Display price, discount and features info for all 3 plans */}
      <div className={styles.cardContainer}>
        <PlanCardWeb
          plan={planDetails.basic}
          activeSubscription={activeSubscription}
          currentSubscription={currentSubscription}
          updateActiveSubscription={updateActiveSubscription}
          displayDiscount={flags.discount_basic && !currentSubscription}
        />
        <PlanCardWeb
          plan={planDetails.standard}
          activeSubscription={activeSubscription}
          currentSubscription={currentSubscription}
          updateActiveSubscription={updateActiveSubscription}
          displayDiscount={flags.discount_standard && !currentSubscription}
        />
        <PlanCardWeb
          plan={planDetails.annual}
          activeSubscription={activeSubscription}
          currentSubscription={currentSubscription}
          updateActiveSubscription={updateActiveSubscription}
          displayDiscount={flags.discount_annual && !currentSubscription}
        />
      </div>

      {/* Display confirm button, close button and relative messages */}
      <ConfirmButtonSection
        activeSubscription={activeSubscription}
        selectToNext={inSignupJourney ? selectToNext : () => processSelection(activeSubscription.toUpperCase())}
        currentSubscription={currentSubscription}
        oneWayChangeDirection={oneWayChangeDirection}
        hasRecurringVouchers={hasRecurringVouchers}
        inSignupJourney={inSignupJourney}
        planDetails={planDetails}
      />

      {/* Only display the rental section when user get into the sign-up journey and not on home page */}
      {inSignupJourney && location.pathname !== '/' && <RentalSection selectToNext={selectToNext} />}
    </div>
  )
}

PlanTableWeb.propTypes = {
  currentSubscription: PropTypes.string,
  selectToNext: PropTypes.func,
  oneWayChangeDirection: PropTypes.string,
  hasRecurringVouchers: PropTypes.string,
  inSignupJourney: PropTypes.bool,
  activeSubscription: PropTypes.string.isRequired,
  updateActiveSubscription: PropTypes.func.isRequired
}

PlanTableWeb.defaultProps = {
  selectToNext: null,
  currentSubscription: '',
  oneWayChangeDirection: '',
  hasRecurringVouchers: '',
  inSignupJourney: false
}

export default PlanTableWeb
