import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { sanitizeDom } from '../../../utils'
import PriceSection from '../price-section'
import { ICONS } from '../constants'
import { useStyles } from './plan-card-web.styles'

/**
 * Component display plan price, discount, features and notes for web view
 */

const PlanCardWeb = React.memo(props => {
  const {
    plan, activeSubscription, updateActiveSubscription, displayDiscount, currentSubscription
  } = props
  const styles = useStyles()

  const isActive = activeSubscription === plan.title

  return (
    <div className={classNames(styles.container, isActive && styles.activeContainer)} onClick={() => updateActiveSubscription(plan.title)}>
      <div className={classNames(styles.header, isActive && styles.activeHeader)}>{plan.header.toUpperCase()}</div>

      <div className={styles.body}>
        <div className={styles.content}>
          <PriceSection
            plan={plan}
            activeSubscription={activeSubscription}
            currentSubscription={currentSubscription}
            displayDiscount={displayDiscount}
          />

          {/* Display plan description e.g. "Binge the best at our lowest price." */}
          <div className={styles.description}>{plan.description}</div>

          {/* Display plan features e.g. "5 profiles for the household" */}
          {plan.planFeaturesWeb.map(item => {
            return (
              <div className={styles.feature}>
                <img alt={item.iconName} className={styles.icon} src={ICONS.find(icon => icon.name === item.iconName).icon || null} />
                <span>{item.value}</span>
              </div>
            )
          })}
        </div>

        {/* Display notes and discount notes e.g. "*Learn more about Ads on Neon." */}
        <div className={styles.notes}>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: sanitizeDom(displayDiscount ? `${plan.discountNotes} ${plan.notes}` : plan.notes) }}
          />
        </div>
      </div>
    </div>
  )
})

PlanCardWeb.propTypes = {
  plan: PropTypes.shape({
    header: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    priceBeforeDot: PropTypes.string,
    priceAfterDot: PropTypes.string,
    unit: PropTypes.string,
    discountPrice: PropTypes.string,
    badge: PropTypes.string,
    description: PropTypes.string,
    planFeaturesWeb: PropTypes.arrayOf(
      PropTypes.shape({
        iconName: PropTypes.string,
        value: PropTypes.string
      }).isRequired
    ).isRequired,
    notes: PropTypes.string,
    discountNotes: PropTypes.string
  }).isRequired,
  activeSubscription: PropTypes.string.isRequired,
  currentSubscription: PropTypes.string.isRequired,
  updateActiveSubscription: PropTypes.func.isRequired,
  displayDiscount: PropTypes.bool.isRequired
}

export default PlanCardWeb
