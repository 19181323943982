import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../shared/common.styles'

const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  title: {
    fontSize: 30,
    fontWeight: 500,
    lineHeight: '50px',
    [theme.media.tablet]: {
      fontSize: 24,
      lineHeight: '30px'
    },
    [theme.media.mobile]: {
      fontSize: 24,
      lineHeight: '30px'
    },
    [theme.media.smallMobile]: {
      fontSize: 24,
      lineHeight: '26px'
    }
  },

  subTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '28px',
    padding: [8, 0, 16, 0],
    [theme.media.tablet]: {
      fontSize: 16,
      lineHeight: '22px'
    },
    [theme.media.mobile]: {
      fontSize: 16,
      lineHeight: '22px',
      padding: [6, 0, 16, 0]
    },
    [theme.media.smallMobile]: {
      fontSize: 16,
      lineHeight: '18px',
      padding: [6, 0, 12, 0]
    }
  }
}))

export default useStyles
