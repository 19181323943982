import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import PlanCardMobile from '../plan-card-mobile'
import TitleSection from '../title-section'
import ConfirmButtonSection from '../confirm-button-section'
import RentalSection from '../rental-section'
import { ICONS } from '../constants'
import { usePlanChangeFlag } from '../../../hooks/usePlanChange'
import { sanitizeDom } from '../../../utils'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { useSubscriptionModalSelection } from '../../add-subscription-modal/hooks/use-subscription-modal-selection'
import useStyles from './plan-table-mobile.styles'

/**
 * Component display plan options for mobile and tablet view
 */

const PlanTableMobile = props => {
  const {
    currentSubscription, selectToNext, oneWayChangeDirection,
    hasRecurringVouchers, inSignupJourney, activeSubscription,
    updateActiveSubscription
  } = props

  const styles = useStyles()
  const location = useLocation()
  const planDetails = usePlanChangeFlag()
  const flags = useFeatureFlags(['discount_basic', 'discount_standard', 'discount_annual'])
  const { processSelection } = useSubscriptionModalSelection()

  const inAModal = location.search?.indexOf('modal=') >= 0
  const plan = [planDetails.basic, planDetails.standard, planDetails.annual].find(item => item.title === activeSubscription)
  const displayDiscount = !currentSubscription && (
    (flags.discount_basic && activeSubscription === planDetails.basic.title) ||
    (flags.discount_standard && activeSubscription === planDetails.standard.title) ||
    (flags.discount_annual && activeSubscription === planDetails.annual.title))

  return (
    <div className={classNames(styles.container, inAModal && styles.extraPadding)}>
      <TitleSection />

      {/* Display price and discount info in 3 plan cards */}
      <div className={styles.cardContainer}>
        <PlanCardMobile
          plan={planDetails.basic}
          activeSubscription={activeSubscription}
          currentSubscription={currentSubscription}
          updateActiveSubscription={updateActiveSubscription}
          displayDiscount={flags.discount_basic && !currentSubscription}
        />
        <PlanCardMobile
          plan={planDetails.standard}
          activeSubscription={activeSubscription}
          currentSubscription={currentSubscription}
          updateActiveSubscription={updateActiveSubscription}
          displayDiscount={flags.discount_standard && !currentSubscription}
        />
        <PlanCardMobile
          plan={planDetails.annual}
          activeSubscription={activeSubscription}
          currentSubscription={currentSubscription}
          updateActiveSubscription={updateActiveSubscription}
          displayDiscount={flags.discount_annual && !currentSubscription}
        />
      </div>

      {/* Display plan features for user selected plan */}
      <div className={styles.body}>
        <div className={styles.description}>{plan.description}</div>

        <div className={styles.header}>{`What's in ${plan.title}?`}</div>

        {plan.planFeaturesMobile.map(item => {
          return (
            <div className={styles.feature}>
              <div className={styles.featureTitle}>
                <img alt={item.iconName} className={styles.icon} src={ICONS.find(icon => icon.name === item.iconName).icon || null} />
                <span>{item.key}</span>
              </div>
              <span>{item.value}</span>
            </div>
          )
        })}

        <div className={styles.notes}>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: sanitizeDom(displayDiscount ? `${plan.discountNotes} ${plan.notes}` : plan.notes) }}
          />
        </div>
      </div>

      {/* Display confirm button, close button and relative messages */}
      <ConfirmButtonSection
        activeSubscription={activeSubscription}
        selectToNext={inSignupJourney ? selectToNext : () => processSelection(activeSubscription.toUpperCase())}
        currentSubscription={currentSubscription}
        oneWayChangeDirection={oneWayChangeDirection}
        hasRecurringVouchers={hasRecurringVouchers}
        inSignupJourney={inSignupJourney}
        planDetails={planDetails}
      />

      {/* Only display the rental section when user get into the sign-up journey and not on home page */}
      {inSignupJourney && location.pathname !== '/' && <RentalSection selectToNext={selectToNext} />}
    </div>
  )
}

PlanTableMobile.propTypes = {
  currentSubscription: PropTypes.string,
  selectToNext: PropTypes.func,
  oneWayChangeDirection: PropTypes.string,
  hasRecurringVouchers: PropTypes.string,
  inSignupJourney: PropTypes.bool,
  activeSubscription: PropTypes.string.isRequired,
  updateActiveSubscription: PropTypes.func.isRequired
}

PlanTableMobile.defaultProps = {
  selectToNext: null,
  currentSubscription: '',
  hasRecurringVouchers: '',
  oneWayChangeDirection: '',
  inSignupJourney: false
}

export default PlanTableMobile
