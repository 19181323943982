
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CarouselComponentFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Carousel"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"header"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"contentType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"subType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sponsorSupportText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sponsorLogoUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sponsorLogoText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tiles"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayMode"},"arguments":[],"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"TileFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":221}};
    doc.loc.source = {"body":"#import \"./tile.gql\"\n\nfragment CarouselComponentFields on Carousel {\n  __typename\n  header\n  contentType\n  subType\n  sponsorSupportText\n  sponsorLogoUrl\n  sponsorLogoText\n  tiles {\n    displayMode\n    ...TileFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./tile.gql").definitions));


      module.exports = doc;
    
