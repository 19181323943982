import {
  always,
  compose,
  cond,
  pathEq,
  values,
  join,
  isEmpty,
  pathOr
} from 'ramda'
import qs from 'query-string'

import { MODALS, SUBSCRIPTION_STATUS } from '../constants'

const { qsParams: modals } = MODALS

export const selectPremiumIntroductionModal = cond([
  [pathEq(['renewalStatus'], SUBSCRIPTION_STATUS.SUSPENDED), always({ modal: modals.subscriptionRepair })],
  [pathEq(['currentSubscription', 'sku'], 'LBPREMTV'), always({ modal: undefined })],
  [pathEq(['status'], SUBSCRIPTION_STATUS.NONE), always({ modal: modals.welcomeBack })]
])

// eslint-disable-next-line import/prefer-default-export
export const getModalLocation = (location, modal, qsParams, state) => {
  const { pathname } = location

  const parsedSearch = qs.parse(location.search)
  const search = qs.stringify({
    ...parsedSearch,
    ...qsParams,
    modal
  })

  return {
    pathname,
    search,
    state: { from: `${location.pathname}${location.search}`, ...state }
  }
}

export const getModalReturnTarget = location => {
  return pathOr('/', ['state', 'from'], location)
}

export const isModalDisplayed = (location) => {
  const parsedSearch = qs.parse(location.search)
  if (isEmpty(parsedSearch)) {
    return false
  }

  if (parsedSearch.modal) {
    return true
  }

  return false
}

export const getModalLocationString = compose(join('?'), values, getModalLocation)
