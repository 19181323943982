import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classNames from 'classnames'
import { T, includes } from 'ramda'
import OnVisible from 'react-on-visible'

import Clamped from '../shared/clamped'
import SlipNSlide from '../../../lib/slip-n-slide'
import { getLocationBaseWithHref } from '../../lib/location'
import {
  slipnslideIsMobile,
  slipnslideCalcTileShowCount,
  slipnslideGetMargin,
  slipnslideCalcSecondaryHeroTileShowCount
} from '../../lib/utils'

import {
  THEME_OPTIONS,
  STATIC_SCREENS,
  CAROUSELS_DO_NOT_LOOP_SLIDES,
  BROWSE_RENTALS
} from '../../constants'

import CarouselTile from '../../containers/carousel/tile'
import MyRentalsTile from './tile/my-rentals'
import ContinuityTile from './tile/continuity-tile'
import TopTenTile from './tile/top-ten'
import SecondaryHeroTile from './tile/secondary-hero'
import CarouselEmptyMyRentals from './carousel-empty-my-rentals'
import NavLinkWithQuery from '../shared/nav-link-with-query'
import styles from './carousel.css'

import CAROUSEL_COMPONENT_FRAGMENT from '../../../graphql/fragments/carousel-component.gql'
import { JSONLD } from '../jsonld/index'
import { createItemListLDSchema } from '../../lib/jsonld/item-list'
import { IdleQueue } from '../../lib/idlize/IdleQueue.mjs'
import { ContinuityContext } from '../../modules/continuity/hooks/continuity-context'
import { GET_ALL_TILES } from '../../modules/continuity/hooks/continuity-reducer'

const getTileType = (carousel) => {
  if (carousel.subType === STATIC_SCREENS.MY_RENTALS.subType) {
    return MyRentalsTile
  }
  if (carousel.subType === 'MY_CONTINUE_WATCHING') {
    return ContinuityTile
  }
  if (carousel.subType === 'TOP_TEN') {
    return TopTenTile
  }
  if (carousel.subType === 'SECONDARY_HERO') {
    return SecondaryHeroTile
  }
  return CarouselTile
}

const renderTiles = (
  carousel,
  Tile,
  theme,
  carouselPosition,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList,
  playbackInfoMany,
  tiles,
  screenWidth
) => {
  // Get the first 10 tiles if sub type is TOP_TEN
  if (carousel.subType === 'TOP_TEN') {
    tiles = tiles.slice(0, 10)
  }

  return (tiles || []).map((tile, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={index}>
      <Tile
        tile={tile}
        subType={carousel.subType}
        contentType={carousel.contentType}
        index={index}
        theme={theme}
        listTitle={carousel.header || ''}
        carouselPosition={carouselPosition}
        isOnMyList={isContentItemOnMyList(tile.contentItem.id)}
        addToMyList={addToMyList}
        removeFromMyList={removeFromMyList}
        playbackInfoMany={playbackInfoMany}
        screenWidth={screenWidth}
      />
    </div>
  ))
}

const getEmptyCarousel = (carousel, theme) => {
  return carousel.subType === STATIC_SCREENS.MY_RENTALS.subType ? (
    <CarouselEmptyMyRentals theme={theme} />
  ) : null
}

const carouselIdleQueue = new IdleQueue({
  defaultMinTaskTime: 5
})

const Carousel = React.memo(({
  carousel,
  theme,
  carouselPosition,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList,
  playbackInfoMany,
  screenWidth,
  isWhiteTheme,
  isMarketing,
  screenTitle,
  isAuthenticated
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const setVisibility = (visible) => {
    if (!visible || visible === isVisible) {
      return
    }

    setIsVisible(true)
  }
  const [{ tiles: continuityTiles }, dispatch] = useContext(ContinuityContext)
  const { tiles } = carousel

  useEffect(() => {
    carouselIdleQueue.pushTask(() => setVisibility(true))

    // Send all continue items to ContinuityContext hook for filter purpose
    if (carousel.subType === 'MY_CONTINUE_WATCHING' && tiles) {
      // set each continue item initial undo state
      const continueTiles = tiles.map(tile => {
        const updateTile = Object.assign(tile, { isInUndoState: false })
        return updateTile
      })
      dispatch({ type: GET_ALL_TILES, tiles: continueTiles })
    }

    return () => {
      carouselIdleQueue.clearPendingTasks()
    }
  }, [])

  if (!isVisible) {
    return <OnVisible onChange={visible => setVisibility(visible)} />
  }

  const checkCarouselDoNotLoopSlides = (type) => {
    return CAROUSELS_DO_NOT_LOOP_SLIDES.includes(type)
  }

  const slides = renderTiles(
    carousel,
    getTileType(carousel),
    theme,
    carouselPosition,
    isContentItemOnMyList,
    addToMyList,
    removeFromMyList,
    playbackInfoMany,
    carousel.subType === 'MY_CONTINUE_WATCHING' ? continuityTiles : tiles,
    screenWidth
  )

  const emptyCarousel = getEmptyCarousel(carousel, theme)
  if ((!slides || !slides.length) && !emptyCarousel) {
    return null
  }
  const contentTypeClass =
    carousel.contentType === 'TITLE'
      ? styles.movieCarousel
      : styles.seriesCarousel

  const isAllContentTVOD = slides && slides.length && carousel.tiles.every(tile => tile.contentItem.isRental)
  const isLoggedOutSecondaryHeroRentals = carousel.subType === 'SECONDARY_HERO' && isAllContentTVOD && !isAuthenticated
  /**
   * Tiles in Top Ten are wider than normal tiles due to the number in front of the image
   * So the number of displaying Top Ten tiles will be showCount - 1, and min number is 2
   * Tiles in BRAND are circular images which are smaller than normal tiles.
   * So the number of displaying BRAND tiles should be more than normal tiles.
   */
  const showCount = slipnslideCalcTileShowCount(screenWidth, 2)
  let slidesToShow = showCount
  if (carousel.subType === 'TOP_TEN' && showCount > 2) {
    slidesToShow = showCount - 1
  }

  if (carousel.subType === 'BRAND') {
    slidesToShow = showCount > 3 ? showCount + 2 : showCount + 1
  }

  if (carousel.subType === 'SECONDARY_HERO') {
    slidesToShow = slipnslideCalcSecondaryHeroTileShowCount(screenWidth, 1)
  }

  const margin = slipnslideGetMargin(screenWidth)
  const isMyRentalsKids =
    carousel.subType === STATIC_SCREENS.MY_RENTALS.subType &&
    theme === THEME_OPTIONS.light

  return (
    <div
      data-lbx-e2e="logged-in-carousel"
      className={classNames(
        styles.carouselComponentWrapper,
        contentTypeClass,
        {
          [styles.whiteTheme]: isWhiteTheme || isMarketing,
          [styles.seriesYMALWrapper]: isYMALSeriesPage(carousel),
          [styles.rentalsCarousel]: isLoggedOutSecondaryHeroRentals
        }
      )}
    >
      <>
        {carousel.header && (
          <h2 className={styles.carouselHeader}>
            <Clamped
              lines={2}
              className={
                classNames({
                  [styles.oneline]: carousel.subType === 'SEARCH'
                })
              }
            >
              {carousel.header}
            </Clamped>
            {carousel.subType === 'SEARCH' && (
              <span className={styles.headerCount}>
                (
                {carousel.tiles.length}
                )
              </span>
            )}
            {// Add line after carousel title
              carousel.subType === STATIC_SCREENS.MY_RENTALS.subType &&
              screenTitle === STATIC_SCREENS.MY_RENTALS.title && (
                <span
                  className={classNames(styles.separator, {
                    [styles.kidsSeparator]: isMyRentalsKids
                  })}
                />
              )
            }
          </h2>
        )}
        {// Show the sponsor header below the carousel header on small screens (splitting to two lines) - breakpoint 768px
          (carousel.sponsorSupportText && carousel.sponsorLogoUrl && carousel.sponsorLogoText) && (
            <div className={styles.carouselSponsorHeader}>
              <div className={styles.carouselSponsorText}>{carousel.sponsorSupportText}</div>
              {// Show logo text instead of logo image on small screens - breakpoint 768px
                <>
                  <img className={styles.carouselSponsorLogoImage} src={carousel.sponsorLogoUrl} alt="Sponsor logo" />
                  <span className={styles.carouselSponsorLogoText}>{carousel.sponsorLogoText}</span>
                </>
              }
            </div>
          )
        }
      </>
      {slides && slides.length ? (
        <SlipNSlide
          arrowClass={theme === THEME_OPTIONS.light ? '' : styles.blackArrow}
          margin={margin}
          swipeMode={slipnslideIsMobile}
          duration={slidesToShow * 180}
          slidesToShow={slidesToShow}
          doNotLoopSlides={checkCarouselDoNotLoopSlides(carousel.subType)}
        >
          {slides}
        </SlipNSlide>
      ) : emptyCarousel}
      {isLoggedOutSecondaryHeroRentals && (
        <>
          <div className={styles.carouselHeader}>
            <p className={styles.rentalsInfo}>Access new release movies from the comfort of your home. Just pick a movie, rent it, and watch instantly – no need to subscribe!</p>
            <p className={styles.rentalsPrice}>Movie rental prices vary from $7.99 for New Release movies, $5.99 for Library movies and from $20.99 for Premium movies.</p>
          </div>
          <div className={styles.buttonsWrapper}>
            <NavLinkWithQuery
              className={classNames(styles.button, styles.isRental)}
              to={BROWSE_RENTALS.url}
            >
              Browse what&apos;s new on rentals
            </NavLinkWithQuery>
          </div>
        </>
      )}
      <JSONLD
        schema={createItemListLDSchema(carousel.tiles)}
        type="ItemList"
      />
    </div>
  )
})

Carousel.propTypes = {
  carousel: propType(CAROUSEL_COMPONENT_FRAGMENT).isRequired,
  theme: PropTypes.oneOf([THEME_OPTIONS.dark, THEME_OPTIONS.light]).isRequired,
  isContentItemOnMyList: PropTypes.func.isRequired,
  carouselPosition: PropTypes.number,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  playbackInfoMany: PropTypes.oneOfType([PropTypes.object]),
  screenWidth: PropTypes.number.isRequired,
  isWhiteTheme: PropTypes.bool,
  isMarketing: PropTypes.bool,
  screenTitle: PropTypes.string,
  isAuthenticated: PropTypes.bool
}

Carousel.defaultProps = {
  carouselPosition: undefined,
  addToMyList: T,
  removeFromMyList: T,
  playbackInfoMany: null,
  isWhiteTheme: false,
  isMarketing: false,
  screenTitle: null,
  isAuthenticated: false
}

export default Carousel

export const isYMALSeriesPage = (carousel) => {
  return (includes('/series/', getLocationBaseWithHref()) || includes('/movie/', getLocationBaseWithHref())) && includes('You May Also Like', carousel.header)
}
