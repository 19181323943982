import React from 'react'

import { priceTableTitle, priceTableSubTitle } from '../constants'
import useStyles from './title-section.styles'

/**
 * Component display price table title and sub title
 */

const TitleSection = () => {
  const styles = useStyles()

  return (
    <>
      <span className={styles.title}>{priceTableTitle}</span>
      <span className={styles.subTitle}>{priceTableSubTitle}</span>
    </>
  )
}

export default TitleSection
