import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '3px solid #434343',
    backgroundColor: '#1a1a1a',
    borderRadius: 16,
    minHeight: 610
  },

  activeContainer: {
    border: '3px solid #cafd34',
    background: `radial-gradient(77.48% 88.69% at 22.52% 41.37%, #2B3C07 0%, #000000 100%)`
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'GothamRounded',
    height: 42,
    borderRadius: [12, 12, 0, 0],
    backgroundColor: '#767676',
    width: '100%',
    color: '#000',
    fontSize: 18,
    fontWeight: 600
  },

  activeHeader: {
    backgroundColor: theme.neonGreen
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 16,
    minHeight: 560,
    height: '100%'
  },

  content: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },

  description: {
    fontSize: 18,
    fontWeight: 500,
    padding: [10, 0, 5, 0],
    '@media only screen and (max-width: 1064px)': {
      minHeight: 60
    }
  },

  feature: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 48,
    width: '100%',
    borderRadius: 24,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: [8, 10, 8, 10],
    marginTop: 12,
    fontSize: 16,
    fontWeight: 500
  },

  icon: {
    paddingRight: 10
  },

  notes: {
    fontSize: 14,
    fontWeight: 500,
    color: 'rgba(255, 255, 255, 0.5)',
    marginTop: 24
  }
}))
