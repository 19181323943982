import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { useStyles } from './rental-section.styles'
import rentalBadge from '../../../../images/neon/rental-badge-small.svg'
import { tvodDetails } from '../constants'

/**
 * Component display rental card under price table in signup journey
 */

const RentalSection = (props) => {
  const styles = useStyles()
  const history = useHistory()
  const { selectToNext } = props

  const browseRental = () => {
    history.push('/rental')
  }

  return (
    <div className={classNames(styles.container)}>
      <div className={styles.header}>
        <div className={styles.content}>
          <div className={styles.title}>{tvodDetails.title}</div>
          <div className={styles.subTitle}>{tvodDetails.subTitle}</div>
          <div className={styles.description}>{tvodDetails.content}</div>
        </div>

        <img className={styles.rentalBadge} src={rentalBadge} alt="Rental Badge" />
      </div>

      <div className={styles.buttonFrame}>
        <div onClick={() => selectToNext(tvodDetails.type)} className={classNames(styles.button, styles.rentMovieButton)}>{tvodDetails.rentMovieButtonText.toUpperCase()}</div>
        <div onClick={browseRental} className={classNames(styles.button, styles.browseMovieButton)}>{tvodDetails.browseMovieButtonText.toUpperCase()}</div>
      </div>
    </div>
  )
}

RentalSection.propTypes = {
  selectToNext: PropTypes.func.isRequired
}

export default RentalSection
