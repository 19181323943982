import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import tickIcon from '../../../../images/neon/tick-circle.svg'
import { useStyles } from './price-section.styles'

/**
 * Component display plan price, disount price, discount label and badge info.
 */

const PriceSection = React.memo(props => {
  const {
    plan, activeSubscription, displayDiscount, currentSubscription
  } = props
  const styles = useStyles()

  const [priceBeforeDot, priceAfterDot] = displayDiscount ? plan.discountPrice?.split('.') : plan.price.split('.')
  const isActive = activeSubscription === plan.title

  return (
    <div className={styles.container}>
      <div className={styles.titleFrame}>
        <span className={styles.title}>{plan.title}</span>
        {isActive && <img className={styles.tickIcon} src={tickIcon} />}
      </div>

      {/* Display either "Your Current Plan" label for existing user or original price for discount sales */}
      <div className={styles.discountPrice}>
        {
          currentSubscription === plan.title && <div className={styles.currentPlanLabel}>Your Current Plan</div>
        }
        {
          displayDiscount &&
          (
            <div className={classNames(styles.originalPrice, isActive && styles.activeOriginalPrice)}>
              &nbsp;$
              {plan.price}
              {plan.unit}
              &nbsp;
            </div>
          )
        }
      </div>

      {/* Display current price */}
      <div className={styles.priceAmount}>
        <span className={styles.currency}>$</span>
        <span className={styles.priceAmountBeforeDot}>{priceBeforeDot}</span>
        <div className={styles.priceAmountRightFrame}>
          <span className={styles.priceAmountAfterDot}>{priceAfterDot}</span>
          <span className={styles.priceAmountUnit}>{plan.unit}</span>
        </div>
      </div>

      {/* Display either badge text e.g "MOST POPULAR" or "LIMITED OFFER" label for discount sales */}
      <div className={styles.badgeFrame}>
        {displayDiscount ? (
          <div className={classNames(styles.discountLabel, isActive && styles.activeDiscountLabel)}>LIMITED OFFER</div>
        ) : (
          <div className={classNames(styles.badge, isActive && styles.activeBadge)}>{plan.badge.toUpperCase()}</div>
        )}
      </div>
    </div>
  )
})

PriceSection.propTypes = {
  plan: PropTypes.shape({
    header: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    priceBeforeDot: PropTypes.string,
    priceAfterDot: PropTypes.string,
    unit: PropTypes.string,
    discountPrice: PropTypes.string,
    badge: PropTypes.string,
    description: PropTypes.string,
    planFeaturesWeb: PropTypes.arrayOf(
      PropTypes.shape({
        iconName: PropTypes.string,
        value: PropTypes.string
      }).isRequired
    ).isRequired,
    notes: PropTypes.string
  }).isRequired,
  activeSubscription: PropTypes.string.isRequired,
  currentSubscription: PropTypes.string.isRequired,
  displayDiscount: PropTypes.bool.isRequired
}

export default PriceSection
